/*------------------------------------*\
        ____            _  __    
       / __"| u        |"|/ /    
      <\___ \/         | ' /     
       u___) |       U/| . \\u   
       |____/>>        |_|\_\    
        )(  (__)     ,-,>> \\,-. 
       (__)           \.)   (_/  

\*------------------------------------*/

/*------------------------------------*\
  ASSETS AND BUILDUP
\*------------------------------------*/

@import 'poly-fluid-sizing';
@import 'include-media';

/*------------------------------------*\
  FONTS
\*------------------------------------*/

@font-face {
    font-family: 'ImpactNieuw-2020Advocate';
    src: url('../fonts/ImpactNieuw-2020Advocate.woff2') format('woff2'),
        url('../fonts/ImpactNieuw-2020Advocate.woff') format('woff');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Chroma';
    src: url('../fonts/ChromaBeta-Light.woff2') format('woff2'),
        url('../fonts/ChromaBeta-Light.woff') format('woff');
    font-style: normal;
    font-weight: 500;
    size-adjust: 92%;

}

@font-face {
    font-family: 'Stempel Garamond';
    src: url('../fonts/StempelGaramond-Regular.woff2') format('woff2'),
        url('../fonts/StempelGaramond-Regular.woff') format('woff');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Stempel Garamond';
    src: url('../fonts/StempelGaramond-Italic.woff2') format('woff2'),
        url('../fonts/StempelGaramond-Italic.woff') format('woff');
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: 'AAF';
    src: url('../fonts/AAFVF.woff2') format('woff2');
    font-style: normal;
    font-weight: 500;
}

/*------------------------------------*\
  VARIABLES
\*------------------------------------*/

$breakpoints: (phone: 320px, tablet: 880px, desktop: 1024px, screen: 1680px);

:root {
    --black: rgb(30, 30, 30);
    --gray: rgb(220, 220, 220);
    --midgray: rgb(152, 152, 152);
    --darkgray: rgb(100, 100, 100);
    --water: rgb(33, 118, 255);
    --ice: rgb(135, 241, 255);
    --corn: rgb(252, 246, 189);
    --eggplant: rgb(186, 165, 255);
    --orange: rgb(229, 133, 39);

    --text-s: 0.7rem;
    --text-m: 1rem;
    --text-l: 2.5rem;

    @include media("<tablet") {
        --text-l: 14vw;
    }

    @include media("<tablet", "landscape") {
        --text-l: 3.4rem;
    }

    --line: 1.15em;
    --line-impact: 0.95em;
    --line-osram: 1.2em;
    --impact-spacing: 0.02em;

    --stroke: 1px;
    --text-stroke: calc(var(--stroke)*1);

    --text-padding: 0.18em;
    --content-padding: 0.24rem;
    --margin: 1.3rem;

    @include media("<tablet") {
        --text-padding: 0.05em;
        --content-padding: 0.08rem;
        --margin: 0.6rem;
    }

    --layout-m: 820px;

    @include media(">=screen") {
        --layout-m: 950px;
    }

    --time-s:500ms;
    --time-m: 800ms;
    --time-l:2s;
    --time-xl:8s;

    --time-curve:cubic-bezier(0.76, 0.33, 0.12, 1);
    --reverse-curve:cubic-bezier(0.2, 0.76, 0.12, 1);

    --img-ratio:0.7;
    --img-width:600px;

    --blur: blur(4px);
}

/*------------------------------------*\
  ROOT
\*------------------------------------*/

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

html,
body {
    // scroll-snap-type: y mandatory;
    @include poly-fluid-sizing('font-size', (320px: 17px, 600px:18px, 1400px:20px, 1920px:24px));
    font-family: 'Stempel Garamond', serif;
    font-weight: normal;
    line-height: var(--line);
    background-color: var(--gray);
    font-variant-ligatures: normal;
}

body {
    margin: var(--margin) auto;
    width: var(--layout-m);
    padding-top: calc(var(--margin) + var(--text-l));
    transition: filter var(--time-s) var(--time-curve);
    padding-bottom: calc(var(--margin) + var(--text-padding)*2 + var(--text-l));
    margin-top: calc(100vh + var(--margin));

    @include media("<tablet") {
        width: auto;
        padding: 0 var(--margin);
        padding-top: var(--text-l);
        padding-bottom: calc(var(--margin)*3 + var(--text-padding)*8 + 4px);
    }
}

body[blinds],
body[info-offen] {
    overflow: hidden;
}

body[landing] {
    margin-top: calc(100vh + var(--margin));
    // scroll-snap-type: mandatory;
    // scroll-snap-points-y: repeat(100vh);
    // scroll-snap-type: y mandatory;
}
body[archiv] {
    margin-top: var(--margin);
}


body * {
    box-sizing: border-box;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a.idle {
    cursor: default;
}

a.idle:hover {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
.impact {
    font-family: 'ImpactNieuw-2020Advocate';
    line-height: 1.05em;
    color: transparent;
    -webkit-text-stroke: var(--text-stroke) black;
    text-transform: uppercase;
    font-size: var(--text-l);
    letter-spacing: var(--impact-spacing);
    font-weight: 500;
}

.clear-impact {
    font-size: var(--text-s);
    line-height: var(--line);
    -webkit-text-stroke: 0;
    color: black;
    text-transform: none;
    text-align: left;
    letter-spacing: 0;
}

.osram {
    font-family: 'Chroma';
}

.text-s {
    font-size: var(--text-s);
    line-height: var(--line);
}

.text-m {
    font-size: var(--text-m);
}

.text-l {
    font-size: var(--text-l);
}

/*------------------------------------*\
  ATTRIBUTE
\*------------------------------------*/

body[info-offen] #archiv,
body[blinds] #archiv {
    filter: blur(0.2rem);
}

/*------------------------------------*\
  FARBE
\*------------------------------------*/

.bg-black {
    background-color: var(--black);
}

.bg-gray {
    background-color: var(--gray);
}

.bg-white {
    background-color: var(--white);
}

.bg-water {
    background-color: var(--water);
}

.bg-ice {
    background-color: var(--ice);
}

.bg-corn {
    background-color: var(--corn);
}

.bg-eggplant {
    background-color: var(--eggplant);
}

.color-black {
    color: var(--black);
}

.color-gray {
    color: var(--gray);
}

.color-white {
    color: var(--white);
}

.color-water {
    color: var(--water);
}

.color-ice {
    color: var(--ice);
}

.color-corn {
    color: var(--corn);
}

.color-eggplant {
    color: var(--eggplant);
}

.color-glass {
    color: transparent;
}

.stroke-white {
    -webkit-text-stroke-color: var(--white);
}

.stroke-gray {
    -webkit-text-stroke-color: var(--gray);
}

.stroke-midgray {
    -webkit-text-stroke-color: var(--midgray);
}

.stroke-darkgray {
    -webkit-text-stroke-color: var(--darkgray);
}

.stroke-glass {
    -webkit-text-stroke-color: transparent;
}

/*------------------------------------*\
  GLOBAL
\*------------------------------------*/

.text-padding {
    padding: var(--text-padding);
}

.border {
    border: var(--stroke) var(--black) solid;
}

.border-gray {
    border-color: var(--gray);
}

.border-white {
    border-color: white;
}

/*------------------------------------*\
  KLAPPEN
\*------------------------------------*/

.zugeklappt {
    display: none;
    overflow: hidden;
    transition: all 2s var(--time-curve) !important;
}

/*------------------------------------*\
  UI ELEMENTE
\*------------------------------------*/

span.ort {
    position: relative;
    display: inline-block;
    padding: 0 0.12em;

    .abkuerzung {
        position: relative;
        width: auto;
        z-index: 2;
    }

    .signet {
        position: absolute;
        border-radius: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transform: scaleY(0.68) translateY(-0.035em);
        border: var(--stroke) solid black;
        z-index: 1;
    }
}

/*------------------------------------*\
  LANDING
\*------------------------------------*/

#landing {
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    display:none;

    svg {
        position: absolute;
        width: 100vw;
        height: 100%;
        z-index: 11;
        padding: var(--margin) calc(var(--margin) + var(--text-s)*1.5);
        overflow: visible;

        @include media("<tablet") {
            padding: calc(var(--margin)*6) var(--margin);
        }

        path {
            fill: transparent;
            stroke: black;
            stroke-width: var(--stroke);
        }

        textPath {
            font-family: 'Chroma';
            fill: var(--water);
            letter-spacing: -0.05rem;
            font-size: var(--text-s);
        }
    }

    section.gallery {
        position: absolute;
        width: 100vw;
        height: 100%;
        margin: 0;
        padding: 0;
        left: 0;
        top: 0;
        z-index: 10;
        overflow: hidden;
    }

    figure {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding:0;
        margin: var(--margin) calc(var(--margin) + var(--text-s)*1.5);
        clip-path: url(#landing-clip);

        @include media("<tablet") {
            margin: calc(var(--margin)*6) var(--margin);
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            object-fit: cover;
        }

        figcaption {
            display: none;
        }
    }

    .tns-nav {
        display: none;
    }

    button {
        display: none;
    }

    section.caption {
        position: absolute;
        font-size: var(--text-s);
        width: 1em;
        height: 100%;
        color: var(--black);
        margin: 0 var(--margin);
        writing-mode: vertical-rl;
        line-height: var(--line-osram);
        transform: rotate(180deg);
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        z-index: 99;
        cursor: pointer;

        @include media("<tablet") {
            transform:none!important;
            writing-mode: unset;
            width:100%;
            height:1rem;
            bottom:calc(var(--margin)*2);
        }
    }

    section.landing-captions:hover {
        .caption {
            text-decoration: underline;
        }
    }

    section.caption.idle {
        opacity: 0;
    }

}

section.snap {
    display: none;
}


body[landing] {
    #landing {
        display: block;
        position: fixed;
        // scroll-snap-align: start;
    }

    section.snap {
        display: block;
        // scroll-snap-align: start;
    }

    section.snap:first-of-type {
        position: absolute;
        top: 0;
    }

}

body[landing-idle]{
    #landing{
        display:block;
    }
}


/*------------------------------------*\
  MAIN
\*------------------------------------*/


article.einleitung,
article.nachwort {
    margin: 0 1px;
}

#veranstaltungen {
    height: auto;
}

section.jahr-liste {
    position: relative;
    min-height: var(--text-l);
    border: var(--stroke) var(--black) solid;
    margin: calc(var(--margin)) 0;

    @include media("<tablet") {
        margin: calc(var(--margin)*2) 0;
    }
}

section.jahr-liste.zugeklappt {
    h2.jahr:hover {
        color: var(--darkgray);
    }
}

h2.jahr {
    display: block;
    width: 100%;
    background-color: var(--midgray);
    color: var(--darkgray);
    text-align: center;
    margin: 0;
    padding: var(--text-padding);
    line-height: var(--line-impact);
    border-bottom: var(--stroke) var(--black) solid;
    cursor: pointer;

    @include media("<tablet") {
        text-align: right;
    }
}

ul.monat-liste {
    margin: 0;
    padding: 0;

    li.monat {
        box-sizing: unset;
        list-style-type: none;
        text-align: center;
        padding: 0 var(--content-padding);
        transition: padding var(--time-m) var(--time-curve);

        @include media("<tablet") {
            padding: 0 var(--margin);
            text-align: left;
        }

        h2 {
            margin: 0;
            border-top: var(--stroke) var(--black) solid;
            line-height: var(--line-impact);
            padding: var(--text-padding) 0;
            transition: padding var(--time-m) var(--time-curve);
        }
    }

    li:first-of-type h2 {
        border: none;
    }

    li.offen {
        background-color: var(--black);
        padding: 0;

        h2 {
            color: var(--gray);
            -webkit-text-stroke-color: var(--darkgray);
            padding: var(--text-padding) var(--margin);
            border-color: var(--gray);

            @include media("<tablet") {
                padding: 0 var(--margin);
            }
        }

    }

    li.offen+li {
        h2 {
            border: none;
        }
    }



}

section.zugeklappt {

    display: block;
    overflow: unset;
    transition: none;

    ul {
        display: none;
        overflow: hidden;
        transition: all 2s var(--time-curve) !important;
    }

    h2.jahr {
        border-width: 0px;
        color: var(--midgray);
    }

    // h2.jahr:hover {
    //     color: var(--darkgray);
    // }

}

section.oeffnen {
    h2.jahr {
        border-width: var(--stroke);
        color: var(--darkgray);
    }

    // h2.jahr:hover {
    //     color: var(--darkgray);
    // }
}

.veranstaltung {
    color: var(--gray);
    -webkit-text-stroke-color: var(--midgray);
    padding: 0 var(--content-padding);

    @include media("<tablet") {
        padding: 0 var(--margin);
    }


    ul.veranstaltung-meta {
        padding: 0;
        line-height: var(--line-impact);
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        column-gap: var(--text-padding);
        color: var(--white);
        padding: var(--text-padding) 0;
        border-top: var(--stroke) var(--gray) solid;
        -webkit-text-stroke-color: var(--darkgray);
        cursor: pointer;

        @include media("<tablet") {
            display: flex;
            flex-wrap: wrap;
            justify-content: left;
            align-items: center;
            gap: 0 1.5rem;
        }


        li {
            list-style: none;
            row-gap: var(--text-padding);
            margin: 0;
            padding: 0;

            @include media("<tablet") {
                row-gap: var(--text-padding);
            }
        }

        li.meta-datum {
            grid-column: 1 / span 2;
            grid-row: 1 / 2;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media("<tablet") {
                order: 0;
            }

            .jahr-4X {
                display: initial;

                @include media("<tablet") {
                    display: none;
                }
            }

            .jahr-2X {
                display: none;

                @include media("<tablet") {
                    display: initial;
                }
            }
        }

        li.meta-ort {
            display: flex;
            flex-direction: column;
            justify-content: center;
            grid-column: 3 / span 1;
            grid-row: 1 / span 2;
            align-items: center;
        }

        li.meta-tag {
            display: flex;
            align-items: center;
            justify-content: center;
            grid-column: 1 / span 2;
            grid-row: 2 / 2;

            @include media("<tablet") {
                width: 100%;
                justify-content: left;
            }

        }

        li.meta-title {
            grid-column: 4 / span 4;
            grid-row: 1 / span 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            -webkit-text-stroke-color: var(--darkgray);
        }



        .ort {
            -webkit-text-stroke-color: var(--darkgray);
            color: var(--white);

            .signet {
                flex: none;
                border-color: var(--gray);
            }
        }

    }

    article {
        text-align: left;
        color: var(--white);
        padding: var(--content-padding);
    }

}

.veranstaltung.zugeklappt {
    cursor: pointer;

    .veranstaltung-meta {
        transition: none !important;
        color: var(--black);
        -webkit-text-stroke-color: var(--gray);
    }

    ul.veranstaltung-meta {
        .ort {
            -webkit-text-stroke-color: var(--gray);
        }
    }
}


.veranstaltung.zugeklappt:hover .veranstaltung-meta {
    color: var(--white);
    -webkit-text-stroke-color: var(--darkgray);

    .color-glass {
        color: var(--white);
        -webkit-text-stroke-color: var(--darkgray);
    }
}



/*------------------------------------*\
  VERANSTALTUNG
\*------------------------------------*/

.veranstaltung-inhalt {

    .veranstaltung-kopf {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 0;

        @include media("<=tablet") {
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
        }

        li {
            display: inline-block;
            padding: 0;
        }

        // li:last-of-type{
        //     flex-grow: 1;
        //     width:100%;
        //     text-align: center;
        //     @include media("<=tablet") {
        //         width:auto;
        //         text-align: inherit;
        //     }
        // }

    }

    .veranstaltung-bilder-rand {

        max-width: var(--img-width);
        margin: 0 auto;

        @include media("<=tablet") {
            max-width: none;
        }

        .veranstaltung-bilder {

            display: flex;
            align-items: center;
            // margin: 1rem 0;

            figure {
                width: 100%;
                height: auto;
                margin: 0;

                section.img-frame {
                    width: 100%;
                    height: 100%;
                }

                img {
                    width: 100%;
                    object-fit: contain;
                    max-height: 500px;
                    max-height: calc(var(--img-width) * var(--img-ratio));

                    @include media("<=tablet") {
                        max-height: 80vh;
                        max-height: calc(100vw * var(--img-ratio));
                    }
                }

                figcaption {
                    padding: 0.4rem 0;
                    // margin-bottom: 1em;
                }
            }

        }

    }

    .tns-nav {

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 0;

        button {
            width: 0.9rem;
            height: 0.9rem;
            border-radius: 50%;
            background-color: var(--black);
            border: white var(--stroke) solid;
            margin: 0 0.4rem;
            padding: 0;
        }

        button.tns-nav-active {
            background-color: white;
        }
    }

    .veranstaltung-anhang {

        margin: 1rem 0;
        margin-top: 2rem;

        a:hover {
            color: var(--darkgray);
            text-decoration: none;
        }

        .pdf-mobil {
            display: none;
        }

        @include media("<=tablet") {
            .pdf-desktop {
                display: none;
            }

            .pdf-mobil {
                display: inline;
            }
        }

    }

    .veranstaltung-foerderung-logos {
        margin: 1rem 0;
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        margin: 0 auto;
        flex-wrap: wrap;

        figure {
            margin: 1rem 0.5rem;
            width: 20%;

            img {

                width: 100%;
            }
        }
    }

    .veranstaltung-foerderung-beschreibung {
        margin: 1rem 0;
    }


}

/*------------------------------------*\
  MENU ARCHIV
\*------------------------------------*/

#menu-archiv {
    position: fixed;
    width: var(--layout-m);
    min-height: calc(var(--text-l) + var(--text-padding) * 2);
    max-height: calc(100vh - var(--margin)*2 - var(--text-l) - var(--text-padding) * 2 - var(--margin));
    height: auto;
    background-color: var(--gray);
    top: var(--margin);
    left: 50vw;
    transform: translateX(-50%);
    overflow: hidden;
    z-index: 99;
    transition: transform var(--time-s) var(--time-curve),
        top var(--time-s) var(--time-curve);

    @include media("<tablet") {
        width: 100vw;
        max-height: 100vh;
        top: 0;
        border: none;
        border-bottom: var(--stroke) var(--black) solid;
    }
}

body[landing] {
    #menu-archiv {
        top: -1px;
        transform: translateY(-100%) translateX(-50%);
        transition: transform var(--time-s) var(--time-curve),
        top var(--time-s) var(--time-curve);
        transition-timing-function: var(--reverse-curve);
    }
}

#menu-archiv.scroll {
    background-color: var(--ice);
    overflow: scroll;

    @include media("<tablet") {
        border-bottom: 0;
    }
}

#menu-archiv {

    .front {
        position: absolute;
        width: 100%;
        height: calc(var(--text-l) + var(--text-padding) * 2);
        color: var(--gray);
        cursor: pointer;
        z-index: 100;
    }

    .front:hover {
        color: var(--ice);
    }

}

#menu-archiv.scroll {
    .front {
        position: sticky;
        top: 0;
        background-color: var(--ice);
        border-bottom: var(--stroke) solid var(--black);
    }

    .blinds-nav {
        padding-top: 0;
    }

    .blinds nav {
        border-top: none;
    }
}


.blinds {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 0;
    left: 0;
    top: 0;
    z-index: 99;
    line-height: var(--line-impact);
    transition: height var(--time-s) var(--time-curve);

    .blinds-nav {
        position: relative;
        padding-top: calc(var(--text-l) + var(--text-padding) * 2);
        background-color: var(--ice);
    }
}

body[blinds] .blinds {
    height: auto;
}

#menu-archiv {

    .blinds nav {
        width: 100%;
        padding: var(--text-padding);
        border-top: var(--stroke) solid var(--black);

        @include media("<tablet") {
            padding: var(--margin);
        }
    }

    .blinds nav.kategorien ul,
    .blinds nav.orte ul {
        margin: 0;
        padding: 0;
    }

    .blinds nav.kategorien li,
    .blinds nav.orte li {
        margin: 0;
        display: inline;
        padding-left: 0;
        cursor: pointer;
    }

    .blinds nav li.active {
        color: var(--gray);

        .abkuerzung {
            color: var(--gray);
        }
    }

    .blinds nav.kategorien li:hover,
    .blinds nav.orte li:hover {
        color: var(--gray);

        span.abkuerzung {
            color: var(--gray);
        }
    }

    .blinds nav.kategorien {
        padding-bottom: 0;
    }

    .blinds nav.orte {
        padding-top: 0;
        border: none;
    }

    .publikationen,
    .publikationen-liste {
        position: relative;
        background-color: var(--orange);
        border-top: var(--stroke) solid var(--black);
        -webkit-text-stroke: 0;
        color: black;
        text-transform: none;
        font-weight: 300;
        padding: 1em var(--margin);
        line-height: var(--line);
        letter-spacing: 0;
    }

    .publikationen {
        cursor: pointer;
    }

    .publikationen-liste {
        margin: 0;
        padding: 0;

        a {
            text-decoration: none;
        }

        li {
            padding: 0.15em var(--margin);
            line-height: 1.5em;
            border-top: var(--stroke) solid var(--black);
            cursor: pointer;
        }
        li.static{
            cursor:default;
        }

        li p {
            margin: 0.5em;
        }

        li p::before {
            font-weight: 500;
            padding-right: 0.5em;
            content: '•';
        }

        li.external p::after {
            // font-weight: 900;
            // font-family: Arial, Helvetica, sans-serif;
            margin-left: 0.5em;
            content: '→';
            transition: margin 200ms var(--time-curve);
        }

        li.external:hover p::after {
            margin-left: 1.5em;
        }

        a:first-of-type li {
            border: 0;
        }
    }

}

/*------------------------------------*\
  SUCHE
\*------------------------------------*/

#suche-blinds {
    display: none;
}

#suche-blinds.aktiv {
    display: block;
}

#suche-info {
    display: none;
    border-top: var(--stroke) var(--black) solid;
    padding: var(--text-padding) var(--content-padding);

    p {
        margin: 0;
        padding: calc(var(--line)*0.5);
        font-family: 'Chroma';
        font-size: var(--text-s);
        line-height: var(--line);
        text-transform: none;
        -webkit-text-stroke: 0;
        color: var(--black);
    }
}

#suche-info.aktiv {
    display: block;
}

#suche {

    ul {
        padding: 0;
        margin: 0;
        padding: var(--text-padding) 0;
    }

    section.veranstaltung {
        padding: 0;
    }

    ul.veranstaltung-meta {
        color: transparent;
        padding: var(--text-padding) var(--content-padding);
        border-top: var(--stroke) var(--black) solid;
        -webkit-text-stroke-color: var(--black);
        cursor: pointer;

        @include media("<tablet") {
            padding: var(--text-padding) var(--margin);
        }

        li.meta-title {
            -webkit-text-stroke-color: var(--black);
            color: transparent;
            text-align: center;

            @include media("<tablet") {
                text-align: left;
            }
        }

        .ort {
            -webkit-text-stroke-color: var(--black);
            color: var(--ice);

            .signet {
                border-color: var(--black);
            }
        }

    }

    ul.veranstaltung-meta:hover {
        color: var(--white);

        li.meta-title {
            color: var(--white);
        }

        .ort {
            color: var(--white);

            .signet {
                border-color: var(--black);
            }
        }
    }
}

/*------------------------------------*\
  MENU INFO
\*------------------------------------*/

#menu-info {
    position: fixed;
    width: 100vw;
    left: 0;
    bottom: 0;
    z-index: 97;
    display: flex;
    // flex-wrap:nowrap;
    // justify-content:stretch;
    // align-items: stretch;
    gap: calc(var(--margin));
    padding: calc(var(--margin));
    transition: transform var(--time-s) var(--time-curve),
        padding var(--time-s) var(--time-curve);

    @include media("<tablet") {
        padding: calc(var(--margin)*1);
        gap: 0;
        flex-direction: column;
        align-items: center;
        padding-bottom: calc(var(--margin)*2);
    }
}

body[landing] {
    #menu-info {
        transform: translateY(100%);
        padding-bottom: 0;
        transition-timing-function: var(--reverse-curve);
    }
}

.info-kategorie {
    position: relative;
    width: 33vw;
    width: calc(100vw / 3 - var(--margin));
    text-align: center;
    cursor: pointer;

    .info-kategorie-text {
        position: relative;

        @include media("<tablet") {
            -webkit-text-stroke-color: transparent;
            display: block;
            color: transparent;
            font-size: 1px;
            line-height: 1px;
            transition: all var(--time-m) var(--time-curve);
        }
    }

}

.info-kategorie:hover {
    color: var(--gray);
}

@include media("<tablet") {

    .border {
        border-color: transparent;
    }

    .info-kategorie {
        width: 100%;
        height: auto;
        pointer-events: none;
        max-height: 4px;
        transition: all var(--time-m) var(--time-curve);
    }

    body[info-offen] {

        .info-kategorie {
            margin: calc(100vh/6 - var(--text-l)*0.667 - var(--margin) - env(safe-area-inset-bottom)) 0;
            border-color: black;
            cursor: pointer;
            max-height: 100vh;
            pointer-events: all;
        }

        .info-kategorie-text {
            -webkit-text-stroke-color: black;
            font-size: var(--text-l);
            line-height: var(--line-impact);
        }

        .info-kategorie:hover {
            color: var(--gray);
        }

    }

}

/*------------------------------------*\
  MENU INFO KLAPPEN
\*------------------------------------*/

.info-blinds {
    position: fixed;
    min-height: 100vh;
    max-height: 100vh;
    width: 33.33vw;
    display: none;
    top: 100vh;
    overflow: scroll;
    padding: var(--margin);
    padding-top: calc(var(--text-l)*1.95 + var(--margin));
    transition: top var(--time-s) var(--time-curve);
    z-index: 98;


    @include media("<tablet") {
        min-height: auto;
        width: 100vw;
        height: 100%;
        top: auto;
        bottom: 0;
        padding-top: calc(var(--text-l) + var(--margin));

        li:first-of-type {
            border: none;
        }

    }

    nav {
        margin: 0;
        padding: 0;
    }

    ul {
        padding: 0;
        margin: 0;
    }

    li {
        list-style-type: none;
        text-align: center;
        border-top: var(--stroke) var(--black) solid;
        padding: var(--text-padding) 0;
        line-height: var(--line-impact);
        cursor: pointer;

        @include media("<tablet") {
            padding: 0;
        }
    }

    li:hover {
        color: var(--gray);
    }

    li:last-of-type {
        border-bottom: var(--stroke) var(--black) solid;
    }

    li.blank {
        cursor: default;
    }

    li.return {
        display: none;

        @include media("<tablet") {
            display: block;
            margin-top: calc(var(--text-l) + var(--margin));
            border: none;
        }
    }

    li.offen {
        color: var(--gray);
        cursor: default;
    }

    strong{
        text-transform: uppercase;
    }

}

.info-blinds[menu="info"] {
    left: 0;
    background-color: var(--water);

    figure {
        background-color: var(--water)
    }
}

.info-blinds[menu="arbeitsorte"] {
    left: 33.33vw;
    background-color: var(--corn);

    figure {
        background-color: var(--corn)
    }
}

.info-blinds[menu="kontakt"] {
    left: 66.66vw;
    background-color: var(--eggplant);

    figure {
        background-color: var(--eggplant)
    }

    section {
        border-top: var(--stroke) var(--black) solid;
        padding: 1rem 0;
    }

    @include media("<tablet") {
        section:first-of-type {
            border-top: none;
            padding-top: 0;
        }
    }
}

body[menu-info] .info-blinds[menu="info"],
body[menu-arbeitsorte] .info-blinds[menu="arbeitsorte"],
body[menu-kontakt] .info-blinds[menu="kontakt"] {
    display: block;

    @include media("<tablet") {
        display: none;
    }
}

body[menu-info-offen] .info-blinds[menu="info"],
body[menu-arbeitsorte-offen] .info-blinds[menu="arbeitsorte"],
body[menu-kontakt-offen] .info-blinds[menu="kontakt"] {
    top: 0;

    @include media("<tablet") {
        display: block;
        top: auto;
        bottom: 0;
        left: 0;
    }
}

.info-inhalt,
.kontakt-kategorie {
    display: none;
    padding-top: 1em;
    font-family: 'Chroma';
    font-weight: 300;
    font-size: var(--text-s);
    line-height: var(--line-osram);

    strong {
        font-weight: 500;
    }

    figure {
        margin: 0;
    }

    img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        mix-blend-mode: multiply;
    }

}

.kontakt-kategorie {
    display: block;

    h2 {
        text-align: center;
        margin: 0;
    }
}

@include media("<tablet") {
    .kontakt-kategorie:last-of-type {
        border-bottom: var(--stroke) var(--black) solid;
    }
}

.kontakt-kategorie:first-of-type {
    border-top: var(--stroke) var(--black) solid;
}

.presse {
    a {
        display: block;
        text-decoration: none;
        width: 100%;
        border: var(--stroke) var(--black) solid;
        text-align: center;
        color: transparent;
        background-color: var(--gray);
    }
}

.kontakt-kategorie.presse {
    a:hover {
        color: var(--eggplant);
    }
}

.foerderinnen {
    img {
        display: block;
        max-width: 80%;
        margin: 0 auto;
    }
}

#info-return {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 96;
    transition: backdrop-filter var(--time-s) var(--time-curve);
}

// body[info-offen],
// body[menu-info-offen],
// body[menu-arbeitsorte-offen],
// body[menu-kontakt-offen]{
//     #info-return{
//     backdrop-filter: var(--blur);
//     }
// }

body[info-offen] #info-return,
body[menu-info] #info-return,
body[menu-arbeitsorte] #info-return,
body[menu-kontakt] #info-return {
    display: block;
}

/*------------------------------------*\
  NEWSLETTER
\*------------------------------------*/

.newsletter {
    form {
        margin-top: 1rem 0;
    }

    input {
        position: relative;
        width: 100%;
        height: auto;
        margin: 0;
        background-color: transparent;
        color: var(--eggplant);
        text-align: center;
        outline: none;
        border: var(--stroke) var(--black) solid;
        caret-color: var(--black);
    }

    input:focus::placeholder {
        -webkit-text-stroke: 0;
    }

    ::placeholder {
        color: transparent;
        opacity: 1;
    }

    :-ms-input-placeholder {
        color: transparent;
    }

    ::-ms-input-placeholder {
        color: transparent;
    }

    input:first-of-type {
        border-bottom: 0;
    }

    input:last-of-type {
        border-top: 0;
    }

    input[type="submit"] {
        cursor: pointer;
    }

    input[type="submit"]:hover {
        color: var(--gray);
    }
}